"use strict";
var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { DcBaseComponent } from "@deleteagency/dc";
import { gsap } from "gsap";
export default class ToggleModeComponent extends DcBaseComponent {
  constructor() {
    super(...arguments);
    __publicField(this, "toggleModeDesign", () => {
      this.tl.to(this.refs.toggle, { duration: 0.3, x: "6px" });
    });
    __publicField(this, "toggleModeDream", () => {
      this.tl.to(this.refs.toggle, { duration: 0.3, x: "32px" });
    });
    __publicField(this, "clearAnimation", () => {
      this.tl.clear();
    });
  }
  static getNamespace() {
    return "toggle-mode";
  }
  static getRequiredRefs() {
    return ["toggle"];
  }
  onInit() {
    this.tl = gsap.timeline({ onComplete: this.clearAnimation });
    const body = document.querySelector("body");
    if (body.classList.contains("designClass"))
      this.element.addEventListener("click", this.toggleModeDesign);
    else
      this.element.addEventListener("click", this.toggleModeDream);
  }
}
